import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import Loader from "components/Loader";
import { Row } from "components/Table";
import { SearchInput } from "components/SearchInput";
import SendReceiptDialog from "components/SendReceiptModal/SendReceiptDialog";

import ReceiptReturnDetails from "./ReceiptReturnDetails";
import ReceiptReturnDialog from "./ReceiptReturnModal/ReceiptReturnDialog";

import { ApplicationState } from "store";
import { actionCreators, ProductToReturn } from "store/ReceiptReturn";
import {
  actionCreators as notificationActions,
  NotificationState,
} from "store/Notification";
import {
  CreateReceiptProductReturnRequest,
  CreateReceiptReturnResponse,
  ReceiptResponse,
  ReceiptReturnResponse,
} from "api";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

interface ReduxProps {
  searchReceiptResult: ReceiptResponse | null;
  productsToReturn: Map<number, ProductToReturn> | null;
  findReceiptReturn: (
    receiptReturnUid: string,
    onSuccess?: () => void,
    onError?: () => void
  ) => void;
  returnProducts: (
    receiptUid: string,
    receiptProducts: CreateReceiptProductReturnRequest[],
    onSuccess?: (data?: CreateReceiptReturnResponse) => void,
    onError?: () => void
  ) => void;
  getReceiptReturn: (
    receiptReturnUid: string,
    onSuccess?: (receipt?: ReceiptReturnResponse) => void,
    onError?: () => void
  ) => void;
  sendReceiptReturn: (
    receiptReturnUid: string,
    email: string,
    fullName: string,
    onSuccess?: () => void,
    onError?: () => void
  ) => void;
  setNotification: (
    payload: Partial<Pick<NotificationState, "message" | "severity">>
  ) => void;
  setProductsToReturn: (
    productsToReturn: Map<number, ProductToReturn> | null
  ) => void;
  setSearchReceiptReturn: (payload: ReceiptResponse | null) => void;
}

const Return: React.FC<ReduxProps> = (props) => {
  const {
    searchReceiptResult,
    findReceiptReturn,
    returnProducts,
    getReceiptReturn,
    setProductsToReturn,
    setSearchReceiptReturn,
  } = props;

  const [showReceiptReturnModal, setShowReceiptReturnModal] = useState(false);
  const [showSendReceiptModal, setShowSendReceiptModal] = useState(false);
  const [isReceiptReturnLoading, setIsReceiptReturnLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selected, setSelected] = useState<Row[]>([]);

  useEffect(() => {
    return () => {
      setProductsToReturn(null);
      setSearchReceiptReturn(null);
      setSelected([]);
      setSearchText("");
    };
  }, []);

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(
      /[A-Za-z\u00C0-\u02AF\u0370-\u1FFF\u2C00-\uD7FF]/g,
      ""
    );

    setSearchText(value);
  };

  const clearValue = () => {
    setSearchText("");
  };

  const onGetReceiptReturn = (receiptReturnUid: string) => {
    setShowReceiptReturnModal(true);
    setIsReceiptReturnLoading(true);

    getReceiptReturn(
      receiptReturnUid,
      () => {
        setIsReceiptReturnLoading(false);
      },
      () => {
        setIsReceiptReturnLoading(false);
      }
    );
  };

  const onReturnProducts = () => {
    analytics().sendEvent(AnalyticsEvents.return_products_btn);

    if (selected.length < 1) {
      const { setNotification } = props;
      setNotification({
        message: "Виберіть товари для повернення",
        severity: "error",
      });
      return;
    }

    setIsReceiptReturnLoading(true);

    searchReceiptResult?.receiptUid &&
      returnProducts(
        searchReceiptResult?.receiptUid,
        selected as CreateReceiptProductReturnRequest[],
        (data?: CreateReceiptReturnResponse) => {
          data?.receiptReturnUid && onGetReceiptReturn(data?.receiptReturnUid);
          analytics().sendEvent(AnalyticsEvents.return_products);
          setProductsToReturn(null);
          setSearchReceiptReturn(null);
          setSelected([]);
          setSearchText("");
        },
        () => {
          setIsReceiptReturnLoading(false);
        }
      );
  };

  const onFindReceipt = () => {
    if (searchText) {
      analytics().sendEvent(AnalyticsEvents.return_find_receipt);
      setIsLoading(true);

      setProductsToReturn(null);
      setSearchReceiptReturn(null);
      setSelected([]);

      findReceiptReturn(
        searchText,
        () => {
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
        }
      );
    }
  };

  const onEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      (event.target as HTMLInputElement).blur();
    }
  };

  const sendReceipt = (
    receiptReturnUid: string,
    email: string,
    fullName: string,
    onSuccess?: () => void,
    onError?: () => void
  ) => {
    const { sendReceiptReturn } = props;

    sendReceiptReturn(receiptReturnUid, email, fullName, onSuccess, onError);
    analytics().sendEvent(AnalyticsEvents.return_send_receipt);
  };

  return (
    <>
      <ReceiptReturnDialog
        isOpenModal={showReceiptReturnModal}
        receiptIsLoading={isReceiptReturnLoading}
        onCloseModal={() => setShowReceiptReturnModal(false)}
        onSendReceipt={() => setShowSendReceiptModal(true)}
      />
      <SendReceiptDialog
        isReturnReceipt
        sendReceipt={sendReceipt}
        isOpenModal={showSendReceiptModal}
        onCloseModal={() => setShowSendReceiptModal(false)}
      />
      <Box display="flex" alignItems="center">
        <Typography variant="h4" mt={3} mb={2} component="h1">
          Повернення
        </Typography>
      </Box>
      <Box display="flex">
        <SearchInput
          fullWidth
          value={searchText}
          onChange={onSearchChange}
          onKeyDown={onEnter}
          onBlur={onFindReceipt}
          placeholder="Введіть фіскальний чи порядковий номер чеку"
          clearValue={clearValue}
        />
        <Button
          sx={{ marginLeft: "16px" }}
          size="large"
          variant="contained"
          onClick={onFindReceipt}
        >
          Знайти
        </Button>
      </Box>
      {isLoading ? (
        <Box height="80%">
          <Loader />
        </Box>
      ) : (
        searchReceiptResult && (
          <>
            <ReceiptReturnDetails
              receipt={searchReceiptResult}
              selected={selected}
              setSelected={setSelected}
              onReturnProducts={onReturnProducts}
              isReceiptReturnLoading={isReceiptReturnLoading}
            />
          </>
        )
      )}
    </>
  );
};

const mapStateToProps = ({ receiptReturn }: ApplicationState) => ({
  searchReceiptResult: receiptReturn.searchReceiptResult,
  productsToReturn: receiptReturn.productsToReturn,
});

const mapDispatchToProps = {
  findReceiptReturn: actionCreators.findReceiptReturn,
  returnProducts: actionCreators.returnProducts,
  getReceiptReturn: actionCreators.getReceiptReturn,
  setProductsToReturn: actionCreators.setProductsToReturn,
  setSearchReceiptReturn: actionCreators.setSearchReceiptReturn,
  sendReceiptReturn: actionCreators.sendReceiptReturn,
  setNotification: notificationActions.setNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Return);
