import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { Drawer } from "./styles";

import DiscountBlock from "./DiscountBlock";
import OrderProductItem from "./OrderProductItem";
import PaymentDialog from "./PaymentModal/PaymentDialog";
import ReceiptDialog from "./ReceiptModal/ReceiptDialog";
import SendReceiptDialog from "components/SendReceiptModal/SendReceiptDialog";

import { MonoQrInvoiceDto, PaymentTypeEnum, ReceiptResponse } from "api";
import { ApplicationState } from "store";
import { DiscountTypeEnum, OrderState } from "store/Order";
import { actionCreators } from "store/Receipt";
import { actionCreators as orderActionCreators } from "store/Order";

import { getPriceFormat } from "shared/functions";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

import theme from "theme";

interface ReduxProps {
  createReceipt: (
    workShiftId: number,
    paymentType: PaymentTypeEnum,
    order: OrderState,
    onSucess?: (receiptUid?: string) => void,
    onError?: () => void
  ) => void;
  getReceipt: (
    receiptUid: string,
    onSuccess?: (receipt?: ReceiptResponse) => void
  ) => void;
  cleanOrder: () => void;
  setDiscountValue: (value: number) => void;
  createMonoQrInvoice: (
    receiptUid: string,
    onSuccess?: (monoQrInvoice: MonoQrInvoiceDto) => void,
    onError?: () => void
  ) => void;
  getMonoQrInvoice: (
    invoiceId: string,
    monoInvoiceIntervalId: NodeJS.Timeout | undefined,
    onSuccess?: (monoQrInvoice: MonoQrInvoiceDto) => void,
    onError?: () => void
  ) => void;
  setMonoQrInvoice: (
    invoice:
      | (MonoQrInvoiceDto & {
          monoInvoiceIntervalId?: NodeJS.Timeout;
          receiptUid?: string;
        })
      | null
  ) => void;
  sendReceipt: (
    receiptUid: string,
    email: string,
    fullName: string,
    onSuccess?: () => void,
    onError?: () => void
  ) => void;
  order: OrderState;
  workShiftId: number | undefined;
}

const OrderReceipt: FC<ReduxProps> = (props) => {
  const { order } = props;
  const orderProducts = order.products;
  const totalAmount = order.totalAmount;
  const discountValue = order.discount.value || 0;
  const discountType = order.discount.type;
  const paymentType = order.paymentType;

  const discountAmount =
    discountType === DiscountTypeEnum.Amount
      ? discountValue
      : Number(((totalAmount / 100) * discountValue).toFixed(2));

  const amountToPay = totalAmount - discountAmount;

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [showSendReceiptModal, setShowSendReceiptModal] = useState(false);
  const [isReceiptLoading, setIsReceiptLoading] = useState(false);

  const onPaymentModalOpen = () => {
    analytics().sendEvent(AnalyticsEvents.sales_order_payment_btn);
    setShowPaymentModal(true);
  };

  const onPaymentModalClose = () => {
    analytics().sendEvent(AnalyticsEvents.sales_payment_modal_close_btn);
    setShowPaymentModal(false);
  };

  const handleMonoQr = (receiptUid: string) => {
    return (monoQrInvoice: MonoQrInvoiceDto) => {
      const { setMonoQrInvoice, getMonoQrInvoice, getReceipt, cleanOrder } =
        props;

      const monoInvoiceIntervalId: NodeJS.Timeout | undefined = setInterval(
        () => {
          monoQrInvoice?.id &&
            getMonoQrInvoice(monoQrInvoice.id, monoInvoiceIntervalId, () => {
              getReceipt(receiptUid, () => {
                cleanOrder();
                setIsReceiptLoading(false);
              });
            });
        },
        5000
      );
      setMonoQrInvoice({ ...monoQrInvoice, monoInvoiceIntervalId });
    };
  };

  const handlePaymentType = (receiptUid?: string) => {
    const { createMonoQrInvoice, getReceipt, cleanOrder } = props;

    setShowPaymentModal(false);

    setShowReceiptModal(true);
    setIsReceiptLoading(true);

    if (paymentType === PaymentTypeEnum.MonoQr) {
      createMonoQrInvoice(receiptUid!, handleMonoQr(receiptUid!), () => {
        setShowReceiptModal(false);
        setIsReceiptLoading(false);
      });
      return;
    }

    receiptUid &&
      getReceipt(receiptUid, () => {
        cleanOrder();
        setIsReceiptLoading(false);
      });
  };

  const onCreateReceipt = (onComplete: () => void) => {
    const { createReceipt, order, workShiftId } = props;
    const updatedOrder = {
      ...order,
      providedCashAmount:
        order.providedCashAmount &&
        !isNaN(order.providedCashAmount) &&
        order.providedCashAmount !== 0
          ? order.providedCashAmount
          : order.totalAmount,
    };

    createReceipt(
      workShiftId!,
      paymentType,
      updatedOrder,
      (receiptUid?: string) => {
        onComplete();
        handlePaymentType(receiptUid);
      },
      () => {
        onComplete();
      }
    );
  };

  const sendReceipt = (
    receiptUid: string,
    email: string,
    fullName: string,
    onSuccess?: () => void,
    onError?: () => void
  ) => {
    const { sendReceipt } = props;

    sendReceipt(receiptUid, email, fullName, onSuccess, onError);
    analytics().sendEvent(AnalyticsEvents.sales_send_receipt);
  };

  useEffect(() => {
    if (orderProducts.length === 0) {
      const { cleanOrder } = props;
      cleanOrder();
    }
    if (amountToPay < discountAmount) {
      const { setDiscountValue } = props;
      setDiscountValue(0);
    }
  }, [orderProducts]);

  return (
    <>
      <PaymentDialog
        isOpenModal={showPaymentModal}
        onCloseModal={onPaymentModalClose}
        onCreateReceipt={onCreateReceipt}
      />
      <ReceiptDialog
        isOpenModal={showReceiptModal}
        receiptIsLoading={isReceiptLoading}
        onCloseModal={() => setShowReceiptModal(false)}
        onSendReceipt={() => setShowSendReceiptModal(true)}
      />
      <SendReceiptDialog
        sendReceipt={sendReceipt}
        isOpenModal={showSendReceiptModal}
        onCloseModal={() => setShowSendReceiptModal(false)}
      />
      <Drawer variant="permanent" anchor="right">
        <Box sx={styles.contentContainer}>
          {!orderProducts.length ? (
            <Box pl={2} width="70%">
              <Typography variant="h3" mb={2}>
                Товари у чеку:
              </Typography>

              <Typography
                variant="body1"
                fontWeight="100"
                color={({ colors }) => colors.secondaryGrey}
              >
                У вас немає жодного товару в чеку. Додайте товар із таблиці
                ліворуч або скористайтесь пошуком товару.
              </Typography>
            </Box>
          ) : (
            <Box pl={2} pr={2}>
              {orderProducts.map((product, index) => {
                return <OrderProductItem product={product} key={index} />;
              })}
              <DiscountBlock />
            </Box>
          )}
          <Box pl={3} pr={3}>
            <Box sx={styles.bottomTextContainer}>
              <Typography variant="body1">Сума до сплати:</Typography>
              <Typography variant="body1">
                {getPriceFormat(amountToPay)} ₴
              </Typography>
            </Box>
            <Box sx={styles.bottomTextContainer}>
              <Typography fontSize="12px" color={theme.colors.secondaryGrey}>
                Загальна сума:
              </Typography>
              <Typography fontSize="12px" color={theme.colors.secondaryGrey}>
                {getPriceFormat(totalAmount)} ₴
              </Typography>
            </Box>
            <Box sx={styles.bottomTextContainer}>
              <Typography fontSize="12px" color={theme.colors.secondaryGrey}>
                Знижка:
              </Typography>
              <Typography fontSize="12px" color={theme.colors.secondaryGrey}>
                {getPriceFormat(discountAmount)} ₴
              </Typography>
            </Box>
            <Button
              size="large"
              fullWidth
              disabled={
                getPriceFormat(totalAmount) === "0.00" || amountToPay < 0
              }
              onClick={onPaymentModalOpen}
            >
              Перейти до оплати
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

const styles = {
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    padding: "24px 0",
  },
  bottomTextContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "8px",
  },
};

const mapStateToProps = ({ order, workShift }: ApplicationState) => ({
  order: order,
  workShiftId: workShift.workShift?.workShiftId,
});

const mapDispatchToProps = {
  createReceipt: actionCreators.createReceipt,
  getReceipt: actionCreators.getReceipt,
  cleanOrder: orderActionCreators.cleanOrder,
  setDiscountValue: orderActionCreators.setDiscountValue,
  createMonoQrInvoice: actionCreators.createMonoQrInvoice,
  getMonoQrInvoice: actionCreators.getMonoQrInvoice,
  setMonoQrInvoice: actionCreators.setMonoQrInvoice,
  sendReceipt: actionCreators.sendReceipt,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderReceipt);
